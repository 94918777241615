html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

textarea,
select,
input,
button {
    outline: 0;
}
